@use "src/styles/variables" as *;
.shared_with_table_container {
  min-height: 150px;
  .not_shared {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-regular;
    border-radius: 12px;
    min-height: 150px;
    background-color: rgba(53, 89, 252, 0.1);
    width: 100%;
  }
  .share_with_table {
    width: 100%;
    font-family: $font-light;
    font-size: 14px;
    thead {
      font-family: $font-regular;
    }
    .action_columns {
      width: 25%;
      text-align: center;
    }
    .action_td {
      text-align: center;
      svg {
        color: red;
        cursor: pointer;
      }
    }
  }
}

.table_header_row {
  font-family: $font-semi-bold;
  font-size: 14px;
  white-space: nowrap;
}

.table_data_row {
  font-family: $font-light;
  font-size: 14px;
}

.pagination_cta {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: auto;
  border-top: 1px solid #dee2e6;
  padding-top: 18px;
  flex-wrap: wrap;

  .pagination_button {
    background-color: transparent !important;
    color: #333333 !important;
    box-shadow: none;
    &:hover,
    &:focus {
      box-shadow: 1px 1px 8px 1px #bfbfbf;
    }
    &.number_button {
      font-family: $font-light;
      font-size: 14px;
    }
    &.active_page {
      border: 1px solid blue;
    }
  }
}

.log_controls {
  margin-bottom: 20px;
  justify-content: flex-end;
  gap: 20px;
  display: flex;
  align-items: center;
  .refresh_control {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.table_empty_state {
  width: 450px;
  margin: auto;
  height: 250px;
  display: flex;
  background-color: #9e9e9e24;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-family: $font-light;
  font-size: 16px;
}

.log_table_container {
  height: 620px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.log_head_value {
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    color: #a09bce;
    font-size: 14px;
  }
}

.logs_info {
  font-size: 14px;
  font-family: $font-semi-bold;
  margin-right: auto;
  display: block;
  align-items: center;
  gap: 5px;
}

.logs_note {
  font-size: 12px;
  font-family: $font-light;
  color: black;
}

.edit_app_modal {
  max-width: 600px !important;
  &.animated_detailed_app_modal {
    max-width: 1000px !important;
    transition: max-width 0.3s ease-out !important;
  }
}

.info_toggle {
  height: 50px;
}

.stat_modal_container {
  height: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.app_stats_header {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.app_stats_control {
  display: flex;
  gap: 10px;
  margin-left: auto;
}

.total_value {
  font-family: $font-extra-light;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  flex-basis: auto;
  text-align: right;
}

.path_header {
  width: 230px;
}
.method_header {
  width: 130px;
}
.auth_type_header {
  width: 150px;
}
.status_header {
  width: 110px;
}

.time_header {
  width: 130px;
}

.stats_x_axis_label {
  font-family: $font-extra-light !important;
  font-weight: bold;
}

.chart_container {
  margin-top: auto;
}

.modal_body_with_overflow {
  max-height: 620px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4.5px;
    background: #bababa;
  }
}
