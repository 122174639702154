.hue-horizontal {
  background: linear-gradient(
    0deg,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
}

.saturation-white {
  background: -webkit-linear-gradient(
    90deg,
    #fff,
    rgba(255, 255, 255, 0)
  ) !important;
  background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0)) !important;
}
.saturation-black {
  background: -webkit-linear-gradient(0deg, #000, rgba(0, 0, 0, 0)) !important;
  background: linear-gradient(0deg, #000, rgba(0, 0, 0, 0)) !important;
}
