.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f5f5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: #333;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  &::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0px;
    bottom: 0px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    border: 1px solid #e0e0e0;
  }
  &.on {
    &::before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
      background-color: #f5f5f5;
    }
    &::after {
      border: none;
    }
    background-color: #333;
  }
  &.danger {
    &.on {
      background-color: rgb(244, 82, 82);
      &::before {
        background-color: #f5f5f5;
      }
    }
    &::after {
      border: 1px solid rgb(244, 82, 82);
    }
    &::before {
      background-color: rgb(244, 82, 82);
    }
  }
}
