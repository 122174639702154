@use "src/styles/variables" as *;
.custom_css_field_container {
  position: relative;
  margin-bottom: 15px;
  margin-top: 15px;
}

.custom_css_wrap {
  overflow: hidden;
  border: 1px solid #848484;
  border-radius: 8px;
  height: 43px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4.5px;
    background: #bababa;
  }
  &.expanded {
    height: 200px;
    overflow: auto;
  }
}

.custom_css_toggler {
  margin-left: auto;
  right: 0;
  z-index: 999;
  font-size: 0.7rem;
  font-family: $font-light;
  cursor: pointer;
  &:hover {
    color: $color-blue;
  }
}

.remaining_chars {
  position: absolute;
  right: 10px;
  bottom: 2px;
  font-size: 0.7rem;
  font-family: $font-light;
  color: $color-dark-gray;
}
