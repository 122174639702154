.dash-wrapper {
  height: inherit;
  &.no-scroll {
    overflow: hidden;
  }
}

.dash-content {
  display: flex;
  width: 100%;
  min-height: calc(100% - 80px);
}

.sidebar-wrapper {
  width: 18%;
  padding: 25px 0;
  border-right: 1px solid #ebebeb;
  position: fixed;
  height: 100%;
  top: 80px;
}

.sidebar-profile {
  height: 150px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-inline: 25px;
  .sidebar-profile-email {
    font-size: 1rem;
    margin-top: 20px;
    font-family: $font-regular;
    font-weight: bold;
    width: 100%;
    // overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-item {
  padding: 10px 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 1rem;
    font-family: $font-light;
  }
  svg {
    height: 24px;
    width: 24px;
  }
  &:hover {
    background-color: #e8e6e6;
    color: #1111ff;
  }
  &.selected {
    font-weight: bold;
    color: #1111ff;
    background-color: #e8e6e6;
  }
}

.main-wrapper {
  flex-grow: 1;
  padding: 25px;
  position: relative;
  left: 18%;
  top: 80px;
  max-width: calc(100% - 18%);
  &.fixed-height {
    height: calc(100vh - 80px);
  }
}

.main-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
  grid-template-rows: max-content;
}

.empty-state-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 130px);
  &.half-height {
    height: 420px;
  }
}

.app-modal {
  max-width: 350px;
  .modal-header {
    border-bottom: 1px solid #f4f4f4;
  }
  .app-modal-title {
    font-size: 1rem;
    font-family: $font-regular;
    font-weight: bold;
  }
  .modal-footer {
    justify-content: center;
    border-top: 1px solid #f4f4f4;
  }
}
.app-modal-body {
  background-color: #fbfbfc;
  max-height: 470px;
  overflow-y: auto;
}

.app-modal-title {
  font-size: 1rem;
  font-family: $font-regular;
  font-weight: bold;
}
.app-modal-cta {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  width: 100%;
  .link {
    font-size: 0.8rem;
    font-family: $font-light;
    color: var(--bs-secondary);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: var(--bs-dark);
    }
  }
  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
}

.modal-secret {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 5px;
  padding: 8px;
  font-size: 0.75rem;
  font-family: $font-regular;
  background-color: rgba(128, 128, 128, 0.15);
}

.warning {
  color: #6b83fd !important;
  font-weight: bold;
}

.modal-tooltip {
  .tooltip-inner {
    font-size: 12px;
  }
}
.modal-note {
  font-size: 0.75rem;
  font-family: $font-light;
  margin-block: 5px;
  color: #6b83fd;
  align-self: flex-start;
  svg {
    font-size: 20px;
  }
}

.select-input {
  border: 1px solid transparent;
  width: 100%;
  border-radius: 6px;
  font-size: 0.75rem;
  font-family: $font-regular, sans-serif;
  background-color: #eff2f5;
  box-shadow: none !important;

  &.error {
    border: 1px solid red !important;
  }
}

.app-card {
  border-radius: 10px;
  height: 230px;
  display: flex;
  flex-direction: column;
  border: 1px solid #cdd8ed80;
  align-items: center;
  line-height: 1rem;
  padding-block: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 5px 1px #cdd8ed80;
  &:hover {
    box-shadow: 0px 0px 5px 5px #cdd8ed80;
  }
  .app-card-img {
    width: 100%;
    border-bottom: 1px solid #cdd8ed80;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      width: 144px;
      height: 144px;
    }
    .chain-badge {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 0.65rem;
      font-family: $font-regular;
      font-weight: 400;
      padding: 7px;
      border-radius: 5px;
      text-transform: capitalize;
      &.bg-success {
        background-color: #2fcc66 !important;
      }
      &.bg-secondary {
        background-color: #808080 !important;
      }
    }
    .app-alert {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 1.8rem;
      color: #e64552;
    }
    .disable-badge {
      position: absolute;
      font-size: 0.65rem;
      font-family: $font-regular;
      font-weight: 400;
      padding: 7px;
      border-radius: 5px;
      bottom: 10px;
      left: 10px;
    }
  }
  .app-desc {
    width: 100%;
    padding: 10px;
    font-size: 0.75rem;
    position: relative;
    .app-dropdown {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 20px;
      }
      &:hover {
        background-color: #cdd8ed80;
        z-index: 999;
      }
      &:show {
        z-index: 999;
      }
    }
    .app-menu {
      border-radius: 0;
      font-size: 0.75rem;
      padding: 0;
      .app-menu-item {
        padding: 10px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        border-bottom: 1px solid #d2d2d2;
        cursor: pointer;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .app-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 85%;
  }
  .app-chain {
    color: grey;
    margin-top: 5px;
  }

  &.disabled-card {
    background-color: #b7b6b626;
  }
}

.dash-cta-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 35px;
  .dash-name {
    font-size: 1.2rem;
    font-family: $font-extra-light;
    font-weight: bold;
    color: black;
  }
}

.wallet-setting-wrapper {
  width: 100%;
  border: 1px solid #cdd8ed80;
  padding: 10px;
  border-radius: 10px;
  background-color: #fbfbfc;
  box-shadow: 0px 0px 2px 1px #cdd8ed80 inset;

  .label {
    font-size: 0.7rem;
    font-family: $font-light;
    margin-bottom: 7px;
  }
}

.color-scheme-settings {
  display: flex;
  justify-content: space-between;
  padding-inline: 5px;
  margin-top: 5px;
}
.color-setting {
  margin-block: auto;
  display: flex;
  align-items: center;
}

.disable-apps-empty-state {
  .empty-state {
    transform: translateX(115%);
  }
}
