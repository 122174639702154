@use "src/styles/variables" as *;
.reason_string_select_wrapper {
  margin-top: 15px;
}

.custom_message_select_tooltip_info {
  font-family: $font-light;
  margin-bottom: 5px;
}

.custom_message_tooltip_title {
  font-family: $font-light;
  font-weight: bold;
}

.custom_message_select_tooltip_desc {
  margin-left: 10px;
}
