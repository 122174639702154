@use "src/styles/variables" as *;
.settings_wrapper {
  min-height: 600px;
  padding: 6px 1px;
}
.settings_container {
  display: flex;
  gap: 40px;
}

.settings_item {
  border: 1px solid #adadad;
  border-radius: 8px;
  width: 34%;
}

.settings_cta {
  margin-top: 26px;
  width: 180px;
}

.invalid_email_err {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.settings_save_btn {
  width: 200px;
  height: 42px;
  border-radius: 0px !important;
  background-color: #333 !important;
  color: #fff !important;
  &:disabled {
    background-color: #d9d9d9 !important;
    border: none;
    opacity: 1 !important;
    color: #000 !important;
  }
}

.setting_item_info {
  font-family: $font-light;
  font-size: 0.75rem;
  line-height: 19px;
  text-align: justify;

  .info_note {
    margin-top: 5px;
    text-align: left;
  }
}

.settings_cotainer {
  height: 420px;
  overflow: auto;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4.5px;
    background: #bababa;
  }
}

.setting_header {
  font-family: $font-bold;
  font-size: 1rem;
  line-height: 19px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #adadad;
}
