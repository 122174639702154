@use "src/styles/variables" as *;

.account_keys_item_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 18px;
  &.max_height {
    height: 100%;
  }
}

.account_keys_cta {
  display: flex;
  justify-content: center;
}

.account_key_item {
  border: 1px solid #adadad;
  border-radius: 8px;
  overflow: hidden;
  .account_key_item_header {
    border-bottom: 1px solid #adadad;
    padding: 10px 15px;
    display: flex;
    background-color: #f8f9fa;
    align-items: center;
    gap: 10px;
    .item_header {
      font-family: $font-light;
      font-size: 0.8rem;
    }
    .item_header_delete_icon {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
      &:hover {
        background-color: rgba(205, 216, 237, 0.5019607843);
      }
      svg {
        margin: auto;
        font-size: 18px;
        z-index: 999;
        color: red;
      }
    }
  }

  .account_key_item_body {
    padding: 25px 10px 10px 10px;
  }
}
