@use "src/styles/variables" as *;

.theme_tooltip_info {
  font-family: $font-light;
  font-weight: bold;
  margin-bottom: 5px;
}
.theme_tooltip_title {
  font-family: $font-light;
  font-weight: bold;
}

.them_tooltip_desc {
  margin-left: 10px;
}
