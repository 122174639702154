@use "src/styles/variables" as *;

.transaction_speed_tooltip_info {
  font-family: $font-light;
  font-weight: bold;
  margin-bottom: 5px;
}
.transaction_speed_tooltip_title {
  font-family: $font-light;
  font-weight: bold;
}

.transaction_speed_tooltip_desc {
  margin-left: 10px;
}
