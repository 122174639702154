@use "src/styles/variables" as *;

.account_sharing_item_wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 18px;
  &.max_height {
    height: 100%;
  }
}

.account_sharing_item {
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: #e9ecef;
  height: 42px;
  padding: 12px;
  border: none;
  border-radius: 6px;
  justify-content: space-between;
}

.account_sharing_email {
  font-size: 0.75rem;
  font-family: $font-regular;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.account_sharing_actions {
  svg {
    cursor: pointer;
    color: red;
    font-size: 18px;
  }
}

.with_access_label {
  font-family: $font-semi-bold;
  font-size: 0.75rem;
  margin-top: 28px;
  margin-bottom: 8px;
}

.account_sharing_items_container {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.account_sharing_item_label {
  font-family: $font-light;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.account_sharing_input {
  margin-bottom: 0px !important;
  .on_err {
    border: 1px solid red !important;
  }
}

.input_err_text {
  font-family: $font-light;
  font-size: 0.8rem;
  line-height: 19px;
  color: red;
  margin-top: 5px;
}

.share_with_container {
  margin-top: 28px;
}

.account_sharing_info {
  margin-top: 28px;
  font-family: $font-light;
  font-size: 0.75rem;
  line-height: 19px;
  text-align: justify;
}
