@use "/src/styles/variables.scss" as *;
.two_factor_auth_setting_wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 18px;
}

.two_factor_setting_cta {
  margin-top: 25px;
}

.two_factor_auth_container {
  height: unset;
}

.authenticator_app_container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-between;
  .authenticator_app_title {
    font-size: 1rem;
    font-family: $font-regular;
    gap: 10px;
  }
  .configured_badge {
    margin-left: 10px;
    background-color: transparent !important;
    color: green;
    border: 1px solid green;
    border-radius: 5px;
    font-family: $font-light !important;
  }
}
