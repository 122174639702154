@use "src/styles/variables" as *;

.settings_header {
  padding: 16px 18px;
  border-bottom: 1px solid #adadad;
  font-size: 1rem;
  font-family: $font-regular;
  font-weight: bold;
}

.settings_empty_state_wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  font-family: $font-extra-light;
  font-size: 16px;

  padding: 20px;
  .settings_empty_state {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #bababa37;
    border-radius: 8px;
  }
}
