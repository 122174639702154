.swatch {
  padding: 5px;
  background: transparent;
  border-radius: 1px;
  display: inline-block;

  cursor: pointer;
  position: relative;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #333;
}

.popover {
  position: absolute;
  z-index: 2;
  top: 0px;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.color_picker_wrapper {
  margin-bottom: 1.25rem;
}

.picker_tooltip_note {
  margin-top: 10px;
}
