@use "src/styles/variables" as *;
.apps_collapse_toggler {
  width: 100%;
  border-bottom: 1.5px solid #000000;
  margin-bottom: 30px;
}

.apps_collapse_button {
  width: 100%;
  border: none !important;
  border-radius: 0 !important;
  text-align: left !important;
  box-shadow: none !important;
  font-size: 1rem !important;
  font-family: $font-light !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  --bs-btn-color: #333 !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-color: #333 !important;
  --bs-btn-focus-box-shadow: none !important;
  --bs-btn-active-color: #333 !important;
  --bs-btn-active-bg: transparent !important;
  --bs-btn-hover-border-color: #000000 !important;
  --bs-btn-active-border-color: #000000 !important;
  --bs-btn-padding-x: 8px !important;
  &.closed {
    border-bottom: none !important;
    .chev_icon {
      transform: rotate(180deg);
    }
  }
  .chev_icon {
    color: #333333;
    font-size: 18px;
    margin-left: auto;
    transition: transform 0.3s ease-in-out;
  }
  &.hide_border {
    border-bottom: none !important;
  }
}

.app_count {
  margin-left: 20px;
  font-size: 14px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: rgba(205, 216, 237, 0.5019607843);
}
