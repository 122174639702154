.auth-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.auth-container {
  width: 100%;
  max-width: 400px;
  padding: 40px 20px;
  border-radius: 20px;
  min-height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .terms-and-conditions {
    margin-top: 20px;
    font-size: 0.8rem;
    color: $color-dark-gray;
    font-family: $font-light;
    line-height: 20px;
    a {
      text-decoration: underline;
    }
  }
}

.auth-btn {
  display: flex;
  margin-top: 35px;
  position: relative;
  flex-direction: column;

  .auth-err {
    font-family: $font-regular;
    color: $color-red;
    position: absolute;
    top: -25px;
    font-size: 0.75rem;
    max-width: fit-content;
  }

  .auth-err-whitelist-error {
    position: relative;
    top: -8px;
  }
}

.auth-form-switch {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.75rem;
  font-family: $font-regular;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .auth-reset-pass {
    text-decoration: underline;
    margin-bottom: 5px;
    color: $color-dark-gray;
    width: fit-content;
    cursor: pointer;
    &:hover {
      color: $color-blue;
    }
  }
  span {
    color: $color-dark-gray;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: $color-blue;
    }
  }
}

.reset-pass-copy {
  font-family: $font-extra-light;
  margin-bottom: 1rem;
}

.auth-modal {
  position: relative;
  .auth-copy {
    font-family: $font-extra-light;
    font-size: 1rem;
    letter-spacing: -0.02em;
    line-height: 35px;

    &.large {
      font-size: 1.35rem;
      margin-top: 25px;
    }
  }
  .qr-code-container {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .qr-code-copy {
      width: 250px;
      font-family: $font-extra-light;
      font-size: 1.5rem;
      letter-spacing: -0.02em;
      line-height: 35px;
    }
  }
  .auth-modal-title {
    font-size: 1rem;
    margin-bottom: 10px;

    font-family: $font-bold;
    span {
      font-size: 0.75rem;
    }
  }
  .modal-content {
    padding: 35px;
  }
  svg {
    align-self: center;
  }
  .code-link {
    color: #3a5cd1;
    cursor: pointer;
    font-size: 0.75rem;
    text-align: right;
    margin-top: 10px;
    &:hover {
      color: #1e41b3;
      text-decoration: underline;
    }
  }
  .auth-modal-footer {
    font-size: 0.8rem;
    color: $color-gray;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 0.04em;
    font-family: $font-extra-light;
    b {
      font-family: $font-bold;
    }
  }
  .auth-err-msg {
    font-family: $font-light;
    color: $color-red;
    font-size: 0.75rem;
    margin-top: 10px;
    text-align: center;
  }
}

.auth-modal-cta {
  display: flex;
  align-items: center;
  margin-top: 45px;
  gap: 30px;
}

.button-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.button-icon {
  font-size: 1.2rem;
  &.apple {
    svg {
      width: 35px;
      height: 30px;
    }
  }
}

.button-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.2;
  font-size: 1rem;
  font-family: $font-regular;
  font-weight: 500;
  span {
    font-size: 0.6rem;
    font-family: $font-regular;
  }
}

.next-link {
  margin-left: auto;
  margin-top: 35px;
  color: #000;
  button {
    font-size: 1.2rem;
  }
}

.auth-modal-tile-container {
  display: flex;
  justify-content: space-between;
  .auth-modal-close-btn {
    margin-bottom: 10px;
    cursor: pointer;
    padding: 1px;
    width: 20px;
    height: 20px;
    &:hover {
      border-radius: 50%;
      background-color: #f1f0f0;
    }
  }
}
