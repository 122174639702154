.font_size_select_container {
  margin-top: 15px;
}

.font_size_input_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.font_size_input {
  flex: 1;
  width: 100%;
}

.font_size_range_input {
  border: none !important;
  padding-left: 0 !important;
}

.input_wrapper {
  margin-bottom: 0 !important;
}
.input_wrapper_text {
  margin-bottom: 0 !important;
  width: 50px;
}

.font_size_output {
  width: 50px;
  text-align: center;
}
