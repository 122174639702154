.create_app_modal {
  overflow-y: unset !important;

  .create_app_dropdown_toggle {
    border: 1px solid #848484 !important;
    border-radius: 6px;
    padding: 0.5rem;
    .dropdown_toggle_placeholder {
      color: #8e8e8e;
    }
    &.has_error {
      border: 1px solid #ff0000 !important;
    }
  }
  .create_app_dropdown_item {
    position: relative;
    padding-left: 1rem;

    &.indented {
      text-indent: 1rem;
    }
    &:hover {
      background-color: #333;
      color: #fff;
    }
    &.sub_item {
      margin-left: 10px;
    }
  }
}

.plan_tag_container {
  position: absolute;
  right: 5%;
  bottom: 50%;
  top: 22%;
}
