.primary-btn {
  min-width: 135px;
  font-size: 0.875rem;
  font-family: $font-regular;
  border-radius: 8px;
  color: $color-white;
  background-color: $color-black;
  transition: all 0.2s ease-in-out;
  &.sharp {
    border-radius: 0;
  }
  &.lg {
    width: 100%;
  }
  &.round {
    border-radius: 20px;
  }
  &.clr-transp {
    background-color: transparent !important;
    color: $color-black !important;
    border: 1px solid rgba(108, 117, 125, 0.24);
  }
  &.clr-black {
    background-color: transparent !important;
    color: $color-black !important;
  }
  &.clr-blue {
    background-color: #1976d2 !important;
    color: #fff !important;
  }
  &:disabled {
    background-color: #d9d9d9 !important;
    opacity: 1;
    border: none;
  }
  &.link {
    background-color: transparent;
    color: $color-black;
    &:hover {
      background-color: #ebebeb;
      box-shadow: none;
    }
  }
  &:focus,
  &:hover {
    box-shadow: 1px 1px 8px 1px #bfbfbf;
    background-color: $color-black;
  }
}

.input-wrapper {
  margin-bottom: 1.25rem;
  position: relative;
  font-family: $font-regular;
  .eye-icon {
    position: absolute;
    top: 45%;
    right: 15px;
    cursor: pointer;
    color: $color-black;
    &.with-copy {
      right: 30px;
    }
  }

  .input-container {
    width: 100%;
    display: flex;
    column-gap: 8px;
    align-items: center;
  }

  .input-icon {
    font-size: 18px;
    cursor: pointer;
    color: $color-black;
  }

  span {
    font-family: $font-extra-light;
    position: absolute;
    color: red;
    font-size: 0.75rem;
  }
  option {
    font-size: 0.75rem;
  }
  &.full-width {
    width: 100%;
  }
  textarea {
    resize: none;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 4.5px;
      background: #bababa;
    }
  }
}

.label-wrapper {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  align-items: center;

  label {
    font-size: 0.7rem;
    font-family: $font-light;
  }
  svg {
    color: #a09bce;
    font-size: 18px;
  }
  sup {
    font-weight: bold;
    color: red;
  }
  button {
    margin-left: auto;
  }
  &.large {
    svg {
      margin-top: 3px;
    }
    label {
      font-size: 16px;
      font-family: $font-bold;
      margin-bottom: 15px;
    }
  }
}

.dropdown-toggle.sub-dropdown-toggle {
  color: grey !important;
}

.dropdown-toggle.sub-dropdown-toggle:hover {
  color: white !important;
}

.dropdown-toggle.sub-dropdown-toggle::after {
  content: "";
  margin-left: 100px;
}

.text-input {
  padding: 0.5rem;
  border: 1px solid $color-mid-gray;
  width: 100%;
  border-radius: 6px;
  font-size: 0.75rem;
  font-family: $font-regular;
  box-shadow: none !important;
  &:focus {
    border-color: $color-black;
  }
  &.error {
    border: 1px solid red !important;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.dropdown-toggle {
  background-color: white;
  border-color: #848484;
  color: $color-black;
  font-size: 0.75rem;
  height: 38px;
}

.dropdown-menu {
  width: 100%;
  background-color: white;
  color: $color-black;
  font-size: 0.75rem;
  font-family: $font-regular;
  max-height: 350px;
  overflow-y: scroll;
}
.dropdown-item {
  padding: 0.5rem;
  width: 100%;
  font-size: 0.75rem;
  font-family: $font-regular;
  &:hover {
    background-color: #333;
    color: #fff;
  }
}

.otp-field-container {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: $font-bold;
    font-size: 2rem;
  }
}

.otp-field {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border-color: $color-black !important;
  box-shadow: none !important;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.error {
    border-color: red;
  }
  &:focus-visible {
    border: 1px solid #e6e6e6 !important;
    box-shadow: none !important;
  }
  &:disabled {
    background: #e9ecef;
    border-color: grey;
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: inherit;
  overflow: hidden;
}
.loader-backdrop {
  background-color: rgba(0, 0, 0, 0.13);
  display: flex;
  border-radius: inherit;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.tooltip-popper {
  background-color: #fefefe;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 10px;
  font-size: 0.75rem;
  box-shadow: 0 0 10px 0 #e5e5e5;
  opacity: 1 !important;
  .tooltip-inner {
    background-color: #fefefe;
    color: $color-black;
    font-size: 0.7rem;
    padding: 0;
    font-family: $font-light;
    max-width: 290px;
    text-align: left;
  }
  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    margin: 5px;
    &::before {
      display: none;
    }
  }
  ul {
    margin: 0;
    padding-left: 1.2rem;
  }
  &[data-popper-placement^="top"] {
    margin-bottom: 5px;
    .arrow {
      bottom: -12px;
      border-width: 5px 5px 0 5px;
      border-color: #e5e5e5 transparent transparent transparent;
    }
  }
  &[data-popper-placement^="bottom"] {
    margin-top: 5px;
    .arrow {
      top: -5px;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #e5e5e5 transparent;
    }
  }
  &[data-popper-placement^="right"] {
    margin-left: 5px;
    .arrow {
      left: -5px;
      border-width: 5px 5px 5px 0;
      border-color: transparent #e5e5e5 transparent transparent;
    }
  }
  &[data-popper-placement^="left"] {
    margin-right: 5px;
    .arrow {
      right: -5px;
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent #e5e5e5;
    }
  }
  a {
    color: blue;
    text-decoration: underline;
  }
}

.tabs-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--bs-light);
  .tab-item {
    flex-grow: 1;
    text-align: center;
    padding: 0.5rem 0;
    font-size: 0.8rem;
    font-family: $font-regular;
    max-width: 160px;
    cursor: pointer;
    &:hover {
      border-bottom: 3px solid #000;
    }
    &.active {
      border-bottom: 3px solid $color-black;
    }
    &:last-child {
      border-right: none;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        border-bottom: none;
      }
    }
  }
}

.accordion-button {
  color: $color-black;
  box-shadow: none !important;
  &:not(.collapsed) {
    color: $color-black;
    background-color: var(--bs-light);
    border-bottom: 1px solid var(--bs-border-color);
    &::after {
      background-image: var(--bs-accordion-btn-icon);
    }
  }
}

.accordion-header {
  button {
    font-size: 0.8rem;
    padding: 10px 15px;
    font-family: $font-light;
    overflow: hidden;
  }

  .trash-icon {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
      background-color: rgba(205, 216, 237, 0.5019607843);
    }
    svg {
      margin: auto;
      font-size: 18px;
      z-index: 999;
      color: red;
    }
  }
}
.accordion-item {
  margin-top: 10px;
  border-top: 1px solid var(--bs-border-color) !important;
  border-radius: var(--bs-accordion-border-radius);
  overflow: hidden;
}
.accordion-body {
  padding: 10px;
  padding-top: 25px;
  font-size: 0.8rem;
  font-family: $font-regular;
  color: $color-black;
}

.modal-header {
  min-height: 64px;
}

.modal-body {
  padding-inline: 20px;
  min-height: 270px;
}

.modal-empty-state {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-light;
  background-color: #e5e2ff25;
  border-radius: 10px;
}

.form-check-input {
  &:checked {
    background-color: #000;
    border-color: #000;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.w-tc-editor {
  --color-fg-default: blue !important;
  --color-prettylights-syntax-entity: crimson !important;
  --color-prettylights-syntax-constant: green !important;
}

.animated_detailed_app_modal {
  transition: max-width 0.3s ease-out !important;
  .modal-body {
    animation: modal-animation 0.3s ease-out !important;
  }
}

@keyframes modal-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.table-responsive {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4.5px;
    background: #bababa;
  }
}

.paid_feature {
  cursor: pointer;
  text-indent: 0;
  .indicator {
    padding: 0px 10px;
    border-radius: 5px;
    color: #fff;
    align-items: center;
    font-weight: bold;
    height: 1.4rem;
    font-size: 12px;
    display: table-cell;
    vertical-align: middle;

    &.unlock {
      background: #d9d9d9;
    }

    &.available {
      background: black;
    }
  }
}
