.react_select__control {
  font-weight: 400;
  min-height: 40px;
  border-radius: 6px !important;
  font-size: 0.75rem;
  font-family: "Inter Regular";
  box-shadow: none !important;
}

.react_select__multi-value__label {
  font-family: "Inter Regular";
  font-size: 75% !important;
}
