@use "src/styles/variables" as *;
.advanced_setting_wrapper {
  border-radius: 4px;
}

.advanced_settings_container {
  width: 100%;
  border: 1.5px solid #000000;
  border-radius: 4px;
  margin-bottom: 1.25rem;
}

.advanced_sttings_toggle {
  width: 100%;
  border: none !important;
  border-bottom: 1.5px solid #000000 !important;
  border-radius: 0 !important;
  text-align: left !important;
  box-shadow: none !important;
  font-size: 0.7rem !important;
  font-family: $font-light !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  --bs-btn-color: #333 !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-color: #333 !important;
  --bs-btn-focus-box-shadow: none !important;
  --bs-btn-active-color: #333 !important;
  --bs-btn-active-bg: transparent !important;
  --bs-btn-hover-border-color: #000000 !important;
  --bs-btn-active-border-color: #000000 !important;
  --bs-btn-padding-x: 8px !important;
  &.closed {
    border-bottom: none !important;
    .chev_icon {
      transform: rotate(180deg);
    }
  }
  .chev_icon {
    color: #333333;
    font-size: 18px;
    margin-left: auto;
    transition: transform 0.3s ease-in-out;
  }
  &.hide_border {
    border-bottom: none !important;
  }
}

.switch_settings_item {
  height: 65px;
}

.settings_item_wrapper {
  padding: 0 8px;
}

.whitelist_input_wrapper {
  margin-top: 15px;
}

.custom_css_collapse {
  margin-bottom: 10px;
  margin-top: 20px;
  border: 1px solid #333;
  border-radius: 8px;
  &.open {
    border-bottom: none;
  }
}

.collapse_toggler {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    transition: transform 0.3s ease-in-out;
  }
  &.disabled {
    cursor: not-allowed;
    svg {
      color: #c4c4c4;
    }
  }

  &.is_closed {
    svg {
      transform: rotate(180deg);
    }
  }
}
