.home-container {
  display: flex;
  height: 100%;
  position: relative;
}

.split-container {
  flex: 1 1;
  position: relative;
  &.black-bg {
    background-color: $color-black;
  }
}

.home-copy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $color-white;

  .home-copy-title {
    font-size: 2.5rem;
    font-family: $font-regular;
    margin-bottom: 45px;
  }
  .home-copy-list {
    font-size: 1.5rem;
    font-family: $font-regular;
  }
  .copy-list-item {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
}

.metakeep-logo {
  position: absolute;
  width: 300px;
  height: 200px;
  top: 35px;
  left: 50px;
  cursor: pointer;
  img {
    width: 70%;
    height: auto;
  }
}
